<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <hash-view v-if="hashid && hashid != ''" :cardid="cardid" :code="code" />
        <calendar v-if="scheduleid && scheduleid != ''"
                  @confirm="onConfirmDoseIntake"
                  :cardid="cardid"
                  :videourls="videourls"
                  :adherenceStatus="status"
                  :reporturl="reportUrl"
                  :expire="expire"
                  :showReward="showReward"
                  :events="events" />
      </b-col>
    </b-row>
  </b-container>
</template>
                      
<script>
import HashView from '@/components/Tag/HashView'
import Calendar from '@/components/Tag/Calendar'
import { apiURL } from "@/app.config";

import { mapState, mapActions } from 'vuex';

export default {
  components: { HashView, Calendar },
  name: 'TagScanned',
  props: {
    tagid: {
      default: undefined,
    },
    tagId: {
      default: undefined,
    },
  },
  computed: {
    ...mapState('tags', [ 'scheduleid', 'events', 'hashid', 'cardid', 'videourls', 'status', 'expire', 'showReward', 'hash']),
    code() {
      return this.hashid || ''
    },
    reportUrl() {
      return `${apiURL}report/${this.hash}/patient`
    },
  },
  methods: {
    ...mapActions('tags', [ 'openBlister' ]),
    onConfirmDoseIntake(dose) {
      let d = Object.assign({tagid: this.tagid}, dose);
      this.$store.dispatch('tags/confirmDoseIntake', d);
    }
  },
  created() {
    let tagid = this.tagId || this.tagid
    if (tagid === undefined) {
      this.$router.push({name: 'login'});
      return;
    }
    this.$store.dispatch('tags/getEvents', tagid);
  },
}
</script>
