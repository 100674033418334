<template>
  <b-card-group deck class="m-3">
    <b-card no-body>
      <h3 slot="header">{{T('Code')}}:</h3>
      <b-card-body>
        <h4>{{ code }}</h4>
      </b-card-body>
      <b-card-footer>
        <small class="text-muted">
          <b>{{T('Card ID')}}:</b>
          {{ cardid }}
        </small>
      </b-card-footer>
    </b-card>
  </b-card-group>
</template>

<script>
export default {
  props: {
    cardid: {
      type: String,
      required: true
    },
    code: {
      type: String,
      required: true
    }
  }
};
</script>
